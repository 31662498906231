export const NormalEffectiveness: number[] = [1, 2, 1, 1, 1, 1, 1, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]
export const FightingEffectiveness: number[] = [1, 1, 2, 1, 1, 0, 0, 1, 1, 1, 1, 1, 1, 2, 1, 1, 0, 2]
export const FlyingEffectiveness: number[] = [1, 0, 1, 1, 0, 2, 0, 1, 1, 1, 1, 0, 2, 1, 2, 1, 1, 1]
export const PoisonEffectiveness: number[] = [1, 0, 1, 0, 2, 1, 0, 1, 1, 1, 1, 0, 1, 2, 1, 1, 1, 0]
export const GroundEffectiveness: number[] = [1, 1, 1, 0, 1, 0, 1, 1, 1, 1, 2, 2, 0, 1, 2, 1, 1, 1]
export const RockEffectiveness: number[] = [0, 2, 0, 0, 2, 1, 1, 1, 2, 0, 2, 2, 1, 1, 1, 1, 1, 1]
export const BugEffectiveness: number[] = [1, 0, 2, 1, 0, 2, 1, 1, 1, 2, 1, 0, 1, 1, 1, 1, 1, 1]
export const GhostEffectiveness: number[] = [0, 0, 1, 0, 1, 1, 0, 2, 1, 1, 1, 1, 1, 1, 1, 1, 2, 1]
export const SteelEffectiveness: number[] = [0, 2, 0, 0, 2, 0, 0, 1, 0, 2, 1, 0, 1, 0, 0, 0, 1, 0]
export const FireEffectiveness: number[] = [1, 1, 1, 1, 2, 2, 0, 1, 0, 0, 2, 0, 1, 1, 0, 1, 1, 0]
export const WaterEffectiveness: number[] = [1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 2, 2, 1, 0, 1, 1, 1]
export const GrassEffectiveness: number[] = [1, 1, 2, 2, 0, 1, 2, 1, 1, 2, 0, 0, 0, 1, 2, 1, 1, 1]
export const ElectricEffectiveness: number[] = [1, 1, 0, 1, 2, 1, 1, 1, 0, 1, 1, 1, 0, 1, 1, 1, 1, 1]
export const PsychicEffectiveness: number[] = [1, 0, 1, 1, 1, 1, 2, 2, 1, 1, 1, 1, 1, 0, 1, 1, 2, 1]
export const IceEffectiveness: number[] = [1, 2, 1, 1, 1, 2, 1, 1, 2, 2, 1, 1, 1, 1, 0, 1, 1, 1]
export const DragonEffectiveness: number[] = [1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 1, 2, 2, 1, 2]
export const DarkEffectiveness: number[] = [1, 2, 1, 1, 1, 1, 2, 0, 1, 1, 1, 1, 1, 0, 1, 1, 0, 2]
export const FairyEffectiveness: number[] = [1, 0, 1, 2, 1, 1, 0, 1, 2, 1, 1, 1, 1, 1, 1, 0, 0, 1]

export const TypeEffectiveness: number[][] = [NormalEffectiveness, FightingEffectiveness, FlyingEffectiveness, PoisonEffectiveness, GroundEffectiveness, RockEffectiveness, BugEffectiveness, GhostEffectiveness, SteelEffectiveness, FireEffectiveness, WaterEffectiveness, GrassEffectiveness, ElectricEffectiveness, PsychicEffectiveness, IceEffectiveness, DragonEffectiveness, DarkEffectiveness, FairyEffectiveness]

export interface TypeEffectivenessObject {
    typeEffectiveness: number[];
    type1: number;
    type2: number;
}

export interface TypeEffectivenessValueObject {
    typeEffectivenessValue: number;
    type1: number;
    type2: number;

}

export function calculateAllEffectivenessCharts(): TypeEffectivenessObject[]{
    const allEffectivnessChart: TypeEffectivenessObject[] = [];
    TypeEffectiveness.forEach((type, index) => {
        var effectivenessChart: number[] = [];
        TypeEffectiveness.forEach((type2, index2) => {
            type.forEach((value, index) => {
                const newValue = value + type2[index];
                effectivenessChart.push(newValue);
            })
            var effectiveness: TypeEffectivenessObject = {
                typeEffectiveness: effectivenessChart,
                type1: index,
                type2: index2,
            }
            allEffectivnessChart.push(effectiveness);
            effectivenessChart = [];
        });
    })
    return allEffectivnessChart;
}

export function calculateEffectivenessSimilarity(resultsEffectivness: number[], testEffectiveness: number[]): number {
    var effectivenessValue: number = 0;
    testEffectiveness.forEach((typeEffectiveness, index) => {
        var resultEffectivness = resultsEffectivness[index];
        var abs = Math.abs(resultEffectivness - typeEffectiveness);
        effectivenessValue += abs;
    })
    return effectivenessValue;
}