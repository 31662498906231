export interface TypeObject {
    type: number;
    question: string;
    answer1: string;
    answer2: string;
    answer3: string;
    answer4: string;
    answer5: string;
}

export enum TypeMapper {
    normal = 0,
    fighting = 1,
    flying = 2,
    poison = 3,
    ground = 4,
    rock = 5,
    bug = 6,
    ghost = 7,
    steel = 8,
    fire = 9,
    water = 10,
    grass = 11,
    electric = 12,
    psychic = 13,
    ice = 14,
    dragon = 15,
    dark = 16,
    fairy = 17
}

export var TypeObjectArray: TypeObject[] = [{
    type: 0,
    question: 'How much do you like things to be interesting or different?',
    answer1: 'Need weirdness/excitement',
    answer2: 'Prefer weirdness/excitement',
    answer3: 'Content with whatever',
    answer4: 'Prefer mundane/routine',
    answer5: 'Need mundane/routine'
},{
    type: 1,
    question: 'How fit are you?',
    answer1: 'Couch potato',
    answer2: 'Weak',
    answer3: 'Average fitness',
    answer4: 'In shape',
    answer5: 'Ripped'
},{
    type: 2,
    question: 'How do you feel about heights?',
    answer1: 'Cannot go on planes',
    answer2: 'Uncomfortable flying',
    answer3: 'Prefer being on land',
    answer4: 'Comfortable with heights',
    answer5: 'Want to/have gone skydiving'
},{
    type: 3,
    question: 'What is your alcohol tolerance?',
    answer1: 'Ethanol allergy/throw up easily',
    answer2: 'Get drunk easily',
    answer3: 'Normal',
    answer4: 'High alcohol tolerance',
    answer5: 'Cannot get drunk'
},{
    type: 4,
    question: 'What is your hygeine level?',
    answer1: 'Clean freak',
    answer2: 'Prefer daily showers/cleanliness',
    answer3: 'Normal hygiene',
    answer4: 'Kind of slovenly',
    answer5: 'Enjoy being dirty'
},{
    type: 5,
    question: 'What is your mattress like?',
    answer1: 'Super plush with additional mattress toppers',
    answer2: 'Soft',
    answer3: 'Normal',
    answer4: 'Firm',
    answer5: 'No mattress/sleep on the floor'
},{
    type: 6,
    question: 'How do you feel about bugs?',
    answer1: 'Entomophobic',
    answer2: 'Dislike bugs',
    answer3: 'No strong feelings',
    answer4: 'Like bugs',
    answer5: 'Bug collector'
},{
    type: 7,
    question: 'How do you feel about ghosts?',
    answer1: 'Currently being haunted',
    answer2: 'Phasmophobic',
    answer3: 'Do not believe in ghosts',
    answer4: 'Unafraid of ghosts',
    answer5: 'Friendly with the spirit world'
},{
    type: 8,
    question: 'How do you feel about technology?',
    answer1: 'Technophobic',
    answer2: 'Dislike technology',
    answer3: 'No strong feelings',
    answer4: 'Prefer modern conveniences',
    answer5: 'Always have the latest tech'
},{
    type: 9,
    question: 'How do you deal with hot weather?',
    answer1: 'Heat intolerant',
    answer2: 'Dislike hot weather',
    answer3: 'No weather preference',
    answer4: 'Like warm weather',
    answer5: 'Prefer hot climates/deserts'
},{
    type: 10,
    question: 'Are you good at swimming?',
    answer1: 'Cannot swim',
    answer2: 'Poor swimmer',
    answer3: 'Decent swimmer',
    answer4: 'Strong swimmer',
    answer5: 'Prefer swimming to walking'
},{
    type: 11,
    question: 'How do you feel about nature/being outside?',
    answer1: 'Have allergies/hayfever',
    answer2: 'Would rather be indoors',
    answer3: 'Nature is fine',
    answer4: 'Enjoy being outside',
    answer5: 'Love activities like hiking, camping, etc'
},{
    type: 12,
    question: 'How do you feel about storms?',
    answer1: 'Afraid of storms/thunder',
    answer2: 'Prefer nice weather',
    answer3: 'No opinions about weather',
    answer4: 'Enjoys when it is crummy outside',
    answer5: 'Aspiring storm chaser'
},{
    type: 13,
    question: 'How do the emotions of others affect you?',
    answer1: 'Cannot cope when others are upset/angry',
    answer2: 'Empath',
    answer3: 'Conscious of the emotions of others',
    answer4: 'Able to remain calm when others are acting up',
    answer5: 'Oblivious'
},{
    type: 14,
    question: 'How do you deal with cold weather?',
    answer1: 'Cold intolerant',
    answer2: 'Dislike cold weather',
    answer3: 'No weather preference',
    answer4: 'Like cool weather',
    answer5: 'Prefer cold climates/snow'
},{
    type: 15,
    question: 'How do you feel about fantasy?',
    answer1: 'Fantasy is cringe',
    answer2: 'Prefer media more grounded in reality',
    answer3: 'No strong feelings towards fantasy',
    answer4: 'Enjoy fantasy settings',
    answer5: 'Active DnD player'
},{
    type: 16,
    question: 'Are you a morning person or a night owl?',
    answer1: 'Morning person',
    answer2: 'Slight preference for daytime',
    answer3: 'Time of day does not matter',
    answer4: 'Slight preference for nighttime',
    answer5: 'Night owl'
},{
    type: 17,
    question: 'Do you prefer things to be stereotypically masculine or feminine?',
    answer1: 'Stereotypically masculine',
    answer2: 'Slightly prefer tough things',
    answer3: 'No preference for gendered labels',
    answer4: 'Slightly prefer cute things',
    answer5: 'Stereotypically feminine'
},]