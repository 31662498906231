import { useState } from "react";
import { TypeMapper, TypeObjectArray } from "../models/TypeObjectModels";
import * as TypeEffectiveness from "../models/TypeEffectiveness";
import { RadioOptions } from "./RadioOptions";
import React from "react";
import "./RadioFull.scss";

interface Props {
  allEffectivenessCharts: TypeEffectiveness.TypeEffectivenessObject[];
}

export const RadioFull: React.FC<Props> = ({ allEffectivenessCharts }) => {
  const [normalEffectiveness, setNormalEffectiveness] = useState(2);
  const [fightingEffectiveness, setFightingEffectiveness] = useState(2);
  const [flyingEffectiveness, setFlyingEffectiveness] = useState(2);
  const [poisonEffectiveness, setPoisonEffectiveness] = useState(2);
  const [groundEffectiveness, setGroundEffectiveness] = useState(2);
  const [rockEffectiveness, setRockEffectiveness] = useState(2);
  const [bugEffectiveness, setBugEffectiveness] = useState(2);
  const [ghostEffectiveness, setGhostEffectiveness] = useState(2);
  const [steelEffectiveness, setSteelEffectiveness] = useState(2);
  const [fireEffectiveness, setFireEffectiveness] = useState(2);
  const [waterEffectiveness, setWaterEffectiveness] = useState(2);
  const [grassEffectiveness, setGrassEffectiveness] = useState(2);
  const [electricEffectiveness, setElectricEffectiveness] = useState(2);
  const [psychicEffectiveness, setPsychicEffectiveness] = useState(2);
  const [iceEffectiveness, setIceEffectiveness] = useState(2);
  const [dragonEffectiveness, setDragonEffectiveness] = useState(2);
  const [darkEffectiveness, setDarkEffectiveness] = useState(2);
  const [fairyEffectiveness, setFairyEffectiveness] = useState(2);
  const [type1, setType1] = useState("");
  const [type2, setType2] = useState("");
  const [displayTypes, setDisplayTypes] = useState(false);
  const images = require.context("../assets/types", true);
  const imageList = images.keys().map((image) => images(image));

  const calculateEffectiveness = () => {
    const typeEffectivenessArray = [
      normalEffectiveness,
      fightingEffectiveness,
      flyingEffectiveness,
      poisonEffectiveness,
      groundEffectiveness,
      rockEffectiveness,
      bugEffectiveness,
      ghostEffectiveness,
      steelEffectiveness,
      fireEffectiveness,
      waterEffectiveness,
      grassEffectiveness,
      electricEffectiveness,
      psychicEffectiveness,
      iceEffectiveness,
      dragonEffectiveness,
      darkEffectiveness,
      fairyEffectiveness,
    ];
    var currentClosestEffectiveness: TypeEffectiveness.TypeEffectivenessValueObject =
      {
        typeEffectivenessValue:
          TypeEffectiveness.calculateEffectivenessSimilarity(
            typeEffectivenessArray,
            allEffectivenessCharts[0].typeEffectiveness
          ),
        type1: allEffectivenessCharts[0].type1,
        type2: allEffectivenessCharts[0].type2,
      };
    allEffectivenessCharts.forEach((effectiveness) => {
      const effectivenesValue =
        TypeEffectiveness.calculateEffectivenessSimilarity(
          typeEffectivenessArray,
          effectiveness.typeEffectiveness
        );
      if (
        effectivenesValue < currentClosestEffectiveness.typeEffectivenessValue
      ) {
        currentClosestEffectiveness = {
          typeEffectivenessValue: effectivenesValue,
          type1: effectiveness.type1,
          type2: effectiveness.type2,
        };
      }
    });
    console.log(currentClosestEffectiveness);
    setType1(TypeMapper[currentClosestEffectiveness.type1]);
    setType2(TypeMapper[currentClosestEffectiveness.type2]);
    setDisplayTypes(true);
  };

  return (
    <>
      <RadioOptions
        image={imageList[0]}
        typeObject={TypeObjectArray[TypeMapper.normal]}
        effectiveness={normalEffectiveness}
        onOptionChange={setNormalEffectiveness}
      />
      <RadioOptions
        image={imageList[1]}
        typeObject={TypeObjectArray[TypeMapper.fighting]}
        effectiveness={fightingEffectiveness}
        onOptionChange={setFightingEffectiveness}
      />
      <RadioOptions
        image={imageList[2]}
        typeObject={TypeObjectArray[TypeMapper.flying]}
        effectiveness={flyingEffectiveness}
        onOptionChange={setFlyingEffectiveness}
      />
      <RadioOptions
        image={imageList[3]}
        typeObject={TypeObjectArray[TypeMapper.poison]}
        effectiveness={poisonEffectiveness}
        onOptionChange={setPoisonEffectiveness}
      />
      <RadioOptions
        image={imageList[4]}
        typeObject={TypeObjectArray[TypeMapper.ground]}
        effectiveness={groundEffectiveness}
        onOptionChange={setGroundEffectiveness}
      />
      <RadioOptions
        image={imageList[5]}
        typeObject={TypeObjectArray[TypeMapper.rock]}
        effectiveness={rockEffectiveness}
        onOptionChange={setRockEffectiveness}
      />
      <RadioOptions
        image={imageList[6]}
        typeObject={TypeObjectArray[TypeMapper.bug]}
        effectiveness={bugEffectiveness}
        onOptionChange={setBugEffectiveness}
      />
      <RadioOptions
        image={imageList[7]}
        typeObject={TypeObjectArray[TypeMapper.ghost]}
        effectiveness={ghostEffectiveness}
        onOptionChange={setGhostEffectiveness}
      />
      <RadioOptions
        image={imageList[8]}
        typeObject={TypeObjectArray[TypeMapper.steel]}
        effectiveness={steelEffectiveness}
        onOptionChange={setSteelEffectiveness}
      />
      <RadioOptions
        image={imageList[9]}
        typeObject={TypeObjectArray[TypeMapper.fire]}
        effectiveness={fireEffectiveness}
        onOptionChange={setFireEffectiveness}
      />
      <RadioOptions
        image={imageList[10]}
        typeObject={TypeObjectArray[TypeMapper.water]}
        effectiveness={waterEffectiveness}
        onOptionChange={setWaterEffectiveness}
      />
      <RadioOptions
        image={imageList[11]}
        typeObject={TypeObjectArray[TypeMapper.grass]}
        effectiveness={grassEffectiveness}
        onOptionChange={setGrassEffectiveness}
      />
      <RadioOptions
        image={imageList[12]}
        typeObject={TypeObjectArray[TypeMapper.electric]}
        effectiveness={electricEffectiveness}
        onOptionChange={setElectricEffectiveness}
      />
      <RadioOptions
        image={imageList[13]}
        typeObject={TypeObjectArray[TypeMapper.psychic]}
        effectiveness={psychicEffectiveness}
        onOptionChange={setPsychicEffectiveness}
      />
      <RadioOptions
        image={imageList[14]}
        typeObject={TypeObjectArray[TypeMapper.ice]}
        effectiveness={iceEffectiveness}
        onOptionChange={setIceEffectiveness}
      />
      <RadioOptions
        image={imageList[15]}
        typeObject={TypeObjectArray[TypeMapper.dragon]}
        effectiveness={dragonEffectiveness}
        onOptionChange={setDragonEffectiveness}
      />
      <RadioOptions
        image={imageList[16]}
        typeObject={TypeObjectArray[TypeMapper.dark]}
        effectiveness={darkEffectiveness}
        onOptionChange={setDarkEffectiveness}
      />
      <RadioOptions
        image={imageList[17]}
        typeObject={TypeObjectArray[TypeMapper.fairy]}
        effectiveness={fairyEffectiveness}
        onOptionChange={setFairyEffectiveness}
      />
      <div className="button-and-result">
        <button className="type-submit" onClick={calculateEffectiveness}>
          Submit
        </button>
        <div>
          {displayTypes && (
            <div className="type-result">
              {type1 === type2 && <div>You are pure {type1} type!</div>}
              {type1 !== type2 && (
                <div>
                  You are {type1} and {type2} type!
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
