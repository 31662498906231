import './App.scss';
import { RadioFull } from './components/RadioFull';
import * as TypeEffectiveness from "./models/TypeEffectiveness";

function App() {
  const allEffectivenessCharts = TypeEffectiveness.calculateAllEffectivenessCharts();

  return (
    <div className="App">
      <RadioFull allEffectivenessCharts={allEffectivenessCharts}/>
    </div>
  );
}

export default App;
