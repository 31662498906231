import React from "react";
import { TypeObject } from "../models/TypeObjectModels";
import "./RadioOptions.scss";

interface Props {
  image: string;
  typeObject: TypeObject;
  effectiveness: number;
  onOptionChange: (effectivenessChange: number) => void;
}

export const RadioOptions: React.FC<Props> = ({
  image,
  typeObject,
  effectiveness,
  onOptionChange,
}) => {
  const wrapper = (e: React.FormEvent<HTMLInputElement>) => {
    const value = +e.currentTarget?.value;
    onOptionChange(value);
  };

  return (
    <>
      <div className="type-question">{typeObject.question}</div>
      <div className="type-answer">
        <label>
          <input
            type="radio"
            name={"type" + typeObject.type}
            value={4}
            id="answer1"
            checked={effectiveness === 4}
            onChange={wrapper}
          />
          <img className="type-answer-image" src={image} alt="Option 1" />
        </label>
        <label>
          <input
            type="radio"
            name={"type" + typeObject.type}
            value={3}
            id="answer2"
            checked={effectiveness === 3}
            onChange={wrapper}
          />
          <img className="type-answer-image" src={image} alt="Option 2" />
        </label>
        <label>
          <input
            type="radio"
            name={"type" + typeObject.type}
            value={2}
            id="answer3"
            checked={effectiveness === 2}
            onChange={wrapper}
          />
          <img className="type-answer-image" src={image} alt="Option 3" />
        </label>
        <label>
          <input
            type="radio"
            name={"type" + typeObject.type}
            value={1}
            id="answer4"
            checked={effectiveness === 1}
            onChange={wrapper}
          />
          <img className="type-answer-image" src={image} alt="Option 4" />
        </label>
        <label>
          <input
            type="radio"
            name={"type" + typeObject.type}
            value={0}
            id="answer5"
            checked={effectiveness === 0}
            onChange={wrapper}
          />
          <img className="type-answer-image" src={image} alt="Option 5" />
        </label>
        <div>{typeObject.answer1}</div>
        <div>{typeObject.answer2}</div>
        <div>{typeObject.answer3}</div>
        <div>{typeObject.answer4}</div>
        <div>{typeObject.answer5}</div>
      </div>
    </>
  );
};
